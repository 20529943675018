import React from 'react';
import PageContainer from '../../components/Container';
import { Grid, Button } from '@mui/material';
import { GlobalContext } from '../../../utils/context';
import { useWindowSize, createAmplitudeEvent, isJobBooked } from '../../../utils/helpers';
import moment from 'moment';

const ExportingComponent = (props) => {
  const { job, setJob, setAlert } = GlobalContext();
  const [width] = useWindowSize();

  const notMyDetails = async () => {
    await createAmplitudeEvent(`Selected "Not My Details"`);
    setAlert({
      active: true,
      contact: false,
      title: `Not you?`,
      message: <React.Fragment>
        Sorry about that - please contact us on <a className="primary-color" href={`tel:${process.env.REACT_APP_CC_PHONE_NUMBER}`}>{process.env.REACT_APP_CC_PHONE_NUMBER}</a> to talk to us and we will be happy to look into this for you
      </React.Fragment>
    })
  }

  const editDetails = async () => {
    await createAmplitudeEvent(`Selected "Edit Details"`);
    props.history.push(`/edit-details`)
  }

  const cancelFlow = async () => {
    await createAmplitudeEvent(`Selected "Cancel"`);
    setJob({ voucher: null });
    await localStorage.removeItem("@currentJob");
  }

  const bookAppointment = async () => {
    await createAmplitudeEvent(isJobBooked(job) ? `Selected "Rearrange Appointment"` : `Selected "Book Appointment"`);
    props.history.push(`/select-appointment`)
  }

  return <PageContainer pageTitle="Details" className="page-details">
    <div className="page-header">
      <div className="left-side">
        {
          isJobBooked(job) ? <React.Fragment>
            <div className="title">Your Appointment</div>
            <div className="description success ">Your appointment has been booked for <b>{moment(job.bookingDetails[0].bookingDate).format("dddd, DD MMM YYYY")}</b></div>
          </React.Fragment> : <React.Fragment>
            <div className="title">Confirm Your Details</div>
            <div className="description">Please make sure your details are correct before booking an appointment.</div>
          </React.Fragment>
        }
      </div>
    </div>
    <Grid container spacing={2}>
      <Grid item xs={12} lg={3}>
        <div className="info-title">Personal</div>
        <div className="info-data">{job.customerDetail.customerName}</div>
        <div className="info-data">{job.customerDetail.mobileNo}</div>
      </Grid>
      <Grid item xs={12} lg={8}>
        <div className="info-title">Vehicle</div>
        <div className="info-data">{job.vehicleDetail.carRegNumber}</div>
        <div className="info-data">{job.vehicleDetail.carMake}</div>
        <div className="info-data">{job.vehicleDetail.carModel}</div>
      </Grid>
      <Grid item xs={12} lg={3}>
        <div className="info-title">Installation Address</div>
        {
          [
            job.installationAddress.fittingAddressLine1,
            job.installationAddress.fittingAddressLine2,
            job.installationAddress.fittingTown,
            job.installationAddress.fittingCounty,
            job.installationAddress.fittingPostalCode
          ].filter((d) => d && d.length > 0).map((data, index) => <div className="info-data" key={index}>{data}</div>)
        }
      </Grid>
      <Grid item xs={12} lg={3}>
          <div className="info-title">Product</div>
          <div className="info-data">{job.jobType}</div>
      </Grid>
      <Grid item xs={12} lg={8}>
        {
          job.isDeployed === false && job.jobEditable === true &&
          <React.Fragment>
            <div className="info-data" style={{ marginTop: 25 }}>
              <div className="line1">
                To change your installation address or primary contact number press Edit Details.
              </div>
              <Button variant="outlined" onClick={editDetails} color="primary" fullWidth={width < 1000} style={{ marginTop: 10 }}>Edit Details</Button>
            </div>
          </React.Fragment>
        }
      </Grid>
      {
        isJobBooked(job) && <React.Fragment>
          <Grid item xs={12}>
            <div className="divider-rs" style={{ marginTop: 16, marginBottom: 16 }} />
            <div className="reminder-warning">
              <div className="line line1">
                We will send you a reminder message the day before your appointment and provide an estimated time slot.
              </div>
              <div className="line line2">
                On the day of your appointment your vehicle keys must be left with a responsible adult who can hand them over to the RS Connect engineer.
              </div>
              <div className="line line2">
                If you would like to change your appointment date please select the button below to rearrange for a more suitable date.
              </div>
              <div className="line line3">
                If you want to speak to a member of our bookings team please contact us on <a href={`tel:${process.env.REACT_APP_CC_PHONE_NUMBER}`}>{process.env.REACT_APP_CC_PHONE_NUMBER}</a>. Our opening hours are Monday to Friday, 9am-5pm.
              </div>
            </div>
          </Grid>
        </React.Fragment>
      }
    </Grid>
    <div className="divider-rs" style={{ marginTop: 16, marginBottom: 16 }} />
    <div className="footer-page">
      <div className="not-my-details" onClick={notMyDetails}>
        Not your details? Click <div className="primary-color" style={{ marginLeft: 4, marginRight: 4 }}>here</div> to let us know.
      </div>
      <div className="buttons">
        {
          job.isDeployed === false && job.jobEditable === true && <React.Fragment>
            <Button variant="contained" color="primary" onClick={bookAppointment} fullWidth={width < 1000} style={{ marginBottom: width < 1000 ? 8 : 0 }}>
              {
                isJobBooked(job) ? `Rearrange Appointment` : `Choose Appointment Date`
              }
            </Button>
          </React.Fragment>
        }
        <Button variant="outlined" color="primary" onClick={cancelFlow} fullWidth={width < 1000} style={{ marginLeft: width > 1000 ? 8 : 0 }}>Cancel</Button>
      </div>
    </div>
  </PageContainer>
}

export default ExportingComponent;
